$section_title_color: #1a9b27;
$section_title_size: 1.1rem;
$section_title_weight: 600;
$section_subtitle_color: #999;

$subsection_title_color: black;
$subsection_title_color_dark: white;
$subsection_title_size: 1.1rem;
$subsection_title_weight: 600;
$subsection_subtitle_color: #999;
$subsection_subtitle_color_dark: #999;

$description_color: #999;
$description_size: 0.8rem;

$sidepanel_background: #242424;
$sidepanel_foreground: white;
$sidepanel_border_color: #ddd;
$sidepanel_border_width: 1px;


$link_not_visited: #347fca;
$link_visited: #347fca;

