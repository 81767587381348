@import "styles";

h1,
h2 {
	margin-bottom: 5px;
}

div:not(.grid).section {
	margin: 3em 0;

	width: fit-content;

	.section-item {
		padding: 5px 0;
		padding-bottom: 5px;

		.title {
			color: $subsection_title_color;
		}
		.title.dark {
			color: $subsection_title_color_dark;
		}

		.subtitle {
			color: $subsection_subtitle_color;
		}
		.subtitle.dark {
			color: $subsection_subtitle_color;
		}

		/*.text {

		}*/
	}


	p {
		margin-bottom: 5px;
	}

	.title {
		color: $section_title_color;
		
		font-size: $section_title_size;
		font-weight: $section_title_weight;
		margin-bottom: 10px;
	}

	.subtitle {
		margin-bottom: 5px;
		color: $section_subtitle_color;
		padding-left: 5px;
	}

	/*.text {

	}*/
}

.sidebar{
	max-width: 410px;
	
	.section-item {

		.subtitle {
			color: $subsection_subtitle_color_dark;
		}
	}
}

.field-description {
	font-size: $description_size;
	font-style: italic;
	color: $description_color;
}

a:visited {
	color: $link_visited;
}

a {
	color: $link_not_visited;
}

.sidebar-paragraph img {
	max-width: 75px;
	max-height: auto;
	margin-right: 10px;
}
