@import "styles";

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}

body,
html {
  margin: 0;
  height: 100%;

  background-color: #efefef;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  margin: 0;
}

.flex-item {
  padding: 10px;
  padding-top: 20px;
  height: 100%;
}

.flex-item.right{
  padding: 25px !important;
  max-width: 40vw !important;

  .section {
    margin-top: 1em !important;
    margin-bottom: 1.5em !important;
  }
}

.sidebar.flex-item {
  min-width: max-content;

  padding-right: 25px;
  padding-left: 40px;
  padding-top: 10px;
  flex: auto;

  background-color: $sidepanel_background;
  color: $sidepanel_foreground;
}

.flex-item:not(.sidebar) {
  text-align: center;

  width: fit-content;
  padding-left: 50px;
  padding-right: 50px;
  flex: right;

  border-left-style: solid;
  border-left-width: $sidepanel_border_width;
  border-left-color: $sidepanel_border_color;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;

  .section-item {
    max-width: 15em;

    background-color: #e6e6e6;

    box-shadow: 1.5px 5px 5px #3f3f3f;

    border-radius: 10px;
    border-color: #aaa;
    border-width: 1px;
    border-style: solid;

    margin: 0.5em;
    margin-bottom: 10px;

    padding: 1em !important;

    transition: all 50ms;

    &:hover {
      transform: scale(1.1);
    }
  }

  /*.section-item {
		border: solid 1px black;
		border-radius: 15px;

		padding: 0.5em;
		padding-left: 1em;
	}*/
}

.language-selector {
  margin: auto;
  margin-bottom: 2em;

  cursor: pointer;

  padding: 4px 1em;

  border: none;
  border-radius: 5px;

  background-color: white;

  box-shadow: 2px 5px 5px #3f3f3f;

  transition: all 250ms;

  &:hover {
    box-shadow: 2px 2px 5px #3f3f3f;
  }
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 800px) {
  html,
  body {
    width: 100vh !important;
    height: 100vw !important;
  }

  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}
